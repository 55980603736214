import React from 'react'
import { PageProps } from 'gatsby'

import { AdjustmentsIcon, ArchiveIcon, CheckIcon, CodeIcon, DocumentAddIcon, GlobeAltIcon, LightningBoltIcon, LockClosedIcon, MailIcon, OfficeBuildingIcon, ScaleIcon, UserCircleIcon } from '@heroicons/react/outline'
import ServiceDetails from '../components/ServiceDetails'
import Footer from '../components/Footer'
import Header from '../components/Header'

const leftFeatureItems = [
  {
    id: 1,
    name: 'Penetration Testing',
    description:
      'Pentest including app, network, infrastructure, cloud and much more.',
    icon: AdjustmentsIcon,
  },
  {
    id: 2,
    name: 'End Point Protection',
    description:
      'Design, configure and deployment.',
    icon: LockClosedIcon
  },
  {
    id: 3,
    name: 'Log Monitoring Solutions',
    description:
      'Monitor, maintain secure operations ',
    icon: CheckIcon,
  },
]
const rightFeatureItems = [
  {
    id: 1,
    name: 'Managed Detection and Response [MDR]',
    description:
      '',
    icon: UserCircleIcon,
  },
  {
    id:2,
    name: 'Compliance Services',
    description:
      'Regulations, standards and frameworks.',
    icon: MailIcon
  },
  {
    id:3,
    name: 'Data Privacy',
    description:
      'Data Privacy regulations, standards and frameworks',
    icon: DocumentAddIcon,
  },
]

const CyberSecurity = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <div className="py-12 bg-white overflow-hidden lg:py-12">
          <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
            </svg>

            <div className="relative">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Cyber-security solutions and services
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Cyber-security solutions and services to help ensure your business is protected against cyber threats and attacks.
              </p>
            </div>

            <ServiceDetails leftFeatures={leftFeatureItems} rightFeatures={rightFeatureItems} />

            
          </div>
        </div>
      </main>
    
      <Footer />
    </div>
  )
}

export default CyberSecurity